////////////////////////////////////////////////////////
var printArray =[];
export function createSpace(xmin, xmax, ymin, ymax){
let graph = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [xmin, ymax, xmax, ymin],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
return graph
  }
///////////////removing the object/////////////////////////////////////////////
function removeIt(board, obj)
{
board.removeObject(obj);
}
///////////////////////repeat an action////////////////////////////////////////////////////
export function repeatTask(n, act)
{
for(let i=0;i<n;i++){
  act(i);
}
}
/////////////////////////////////////////////////////////////////////////////////////////////
export function placeCircles(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/circleWhite.svg', [positionX+i-0.375, positionY-0.375],[0.75,0.75]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:true
  });
}
 return imgs;
}
//////////////////////////
export function placeYCircles(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/circle.svg', [positionX+i-0.375, positionY-0.375],[0.75,0.75]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:true
  });
}
 return imgs;
}
///////////////////////////////////////////Blue Circles////////
export function placeBCircles(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/blueCircle.svg', [positionX+i-0.375, positionY-0.375],[0.75,0.75]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:true
  });
}
 return imgs;
}
////////////////////////////////////////////////////////////////////
export function placeExclaim(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/exclaim.svg', [positionX+i-0.25, positionY-0.25],[0.5,0.5]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:false
  });
}
 return imgs;
}
///////////////////////////////////////////////////////////Checkmarks/////////////////////
export function placeChecks(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/check.svg', [positionX+i-0.25, positionY-0.25],[0.5,0.5]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:false
  });
}
 return imgs;
}
////////////////////////////////////////////////////////////////////////////////////////////
export function placeCross(board){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2+0.5;
  let imgs=[];
  for(let i=0;i<=4;i++){
  imgs[i]=board.create('image',['/assets/cross.svg', [positionX+i-0.25, positionY-0.25],[0.5,0.5]], {
    opacity:1,
    fixed:true,
    highlight:false,
    rotate:0,
    visible:false
  });
}
 return imgs;
}
/////////////////////////////////////////////////////////////////////////////////////
export function cycle(j, max){
  if(j< max){
    j+=1;
  }
  else{
    j=0;
  }
  return j;
}
///////////////////////////////placeQuestion///////////////////
export function placeQuestion(board, msg){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = Math.abs((boundingBox[1]-boundingBox[3])/2)-0.5;
  const m ={
  anchorX: 'left',
  display: 'html',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  color:'black',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(26*board.canvasWidth /800.)};
  ////////////////////////////////////////////////////////
  let imgs =board.create('text', [positionX, positionY,  msg], m);
  return imgs;
}
///////////////////////////////placeComment////////////////////
export function placeComment(board, msg){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2-0.5;
  const m ={
  anchorX: 'left',
  display: 'html',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  color:'black',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(26*board.canvasWidth /800.)};
  ////////////////////////////////////////////////////////
  let imgs =board.create('text', [positionX, positionY-5,  msg], m);
  return imgs;
}
//////////////////////////////////////////////////////////////////////
export function placeFingers(board)
{
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2-0.5;
  let imgs =[];
  const m = {opacity:1, fixed:true, highlight:false, rotate:0, visible:false};
  imgs[0]=board.create('image',['/assets/finger.svg', [positionX-0.45, positionY-1.35],[0.5,0.5]], m);
  imgs[1]=board.create('image',['/assets/finger.svg', [positionX-0.45, positionY-2.35],[0.5,0.5]], m);
  imgs[2]=board.create('image',['/assets/finger.svg', [positionX-0.45, positionY-3.35],[0.5,0.5]], m);
  imgs[3]=board.create('image',['/assets/finger.svg', [positionX-0.45, positionY-4.35],[0.5,0.5]], m);
  return imgs;
}
///////////////////////////////////////////////////////////////////////
export function placeAnswers(board, obj){
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[1]-boundingBox[3])/2-0.5;
  const m ={
  anchorX: 'left',
  display: 'html',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  color:'grey',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(26*board.canvasWidth /800.)};
  ////////////////////////////////////////////////////////
  const letters =['a. ', 'b.  ', 'c. ', 'd.  '];
  let txts =[];
  txts[0] =board.create('text', [positionX+0.15, positionY-1,  ()=>letters[0]+obj[0]], m);
  txts[1] =board.create('text', [positionX+0.15, positionY-2,  ()=>letters[1]+obj[1]], m);
  txts[2] =board.create('text', [positionX+0.15, positionY-3,  ()=>letters[2]+obj[2]], m);
  txts[3] =board.create('text', [positionX+0.15, positionY-4,  ()=>letters[3]+obj[3]], m);
  return txts;
}
//////////////////////////////////
export function moveIt(obj, xnew, ynew){
  obj.moveTo([xnew, ynew], 1000);
}
/////////////////////////////////////////////////////////////////////
export function draw3DBox(board, Pt,col)
{
  let ops = {
  vertices:{visible:false},
  fillOpacity:1,
  fillColor:col,
  highlight:false,
  borders:{strokeColor:'black',
  highlight:false,
  strokeWidth: ()=>Math.round(1*board.canvasWidth/800.)}}
  let f1 =board.create('regularpolygon', [[()=>Pt[0], ()=>Pt[1]], [()=>Pt[0]+1, ()=>Pt[1]], 4],ops);
  let f2 = board.create('regularpolygon', [[()=>Pt[0]+0.5, ()=>Pt[1]+0.5], [()=>Pt[0]+0.5+1, ()=>Pt[1]+0.5], 4],ops);
  let f3 =board.create('polygon', [[()=>Pt[0], ()=>Pt[1]+1], [()=>Pt[0]+1, ()=>Pt[1]+1],[()=>Pt[0]+1+0.5, ()=>Pt[1]+1+0.5],[()=>Pt[0]+0.5, ()=>Pt[1]+1+0.5]], ops);
  let f4 = board.create('polygon', [[()=>Pt[0]+1, ()=>Pt[1]], [()=>Pt[0]+1, ()=>Pt[1]+1],[()=>Pt[0]+1+0.5, ()=>Pt[1]+1+0.5],[()=>Pt[0]+1+0.5, ()=>Pt[1]+0.5]], ops);
  return [f1, f2, f3, f4]
}
////////////////////////////////////////////////////////////////////////////////
export function createAxes(board)
{
  let xAxis =board.create('axis', [[-1, 0],[1, 0]], {
    strokeColor:'grey',
    highlight:false,
    strokeWidth: ()=>Math.round(2*board.canvasWidth/800.),
    withLabel:true,
    name: '',
      label: {
        display:'internal',
        fontSize:function(){return 24*board.canvasHeight/800},
        CssStyle: 'fontFamily:Oswald;fontWeight:bold',
        position: 'rt',
        offset: [-20, 20]}});
  let yAxis =board.create('axis', [[0, -1],[0, 1]], {
    strokeColor:'grey',
    highlight:false,
    strokeWidth: ()=>Math.round(2*board.canvasWidth/800.),
    withLabel:true,
    name: '',
      label: {
        display:'internal',
        fontSize:function(){return 24*board.canvasHeight/800},
        CssStyle: 'fontFamily:Oswald;fontWeight:bold',
        position: 'rt',
        offset: [20, -20]}
  });
  return [xAxis, yAxis];
}
//////////////////////////////////
export function drawPerp(board, Ln, Pt){
  const lm ={
  fixed:true,
  strokeColor:'black',
  highlight:false,
  perpendicularpoint:{visible:false},
  strokeWidth: () => Math.round(2*board.canvasWidth /800.)};
  let le=board.create('perpendicular', [Ln, [()=>Pt.X(), ()=>Pt.Y()]], lm);
  return le;
}
///////////////////////////////////////////////////////////////////
export function drawPerpP(board, Ln, Pt){
  const ptA = {
    name:'',
    size: ()=>Math.round(2*board.canvasWidth/800.),
    fillColor:'white',
    strokeColor:'white',
    visible:false,
    highlight:false};
  let le=board.create('perpendicularpoint', [Ln, [()=>Pt.X(), ()=>Pt.Y()]], ptA);
  return le;
}
///////////////////////////////////////////////////////////
export function drawIntersection(board, Ln1, Ln2)
{
  const ptA = {
    name:'',
    size: ()=>Math.round(2*board.canvasWidth/800.),
    color:'black',
    strokeWidth:()=>Math.ceil(4*board.canvasWidth/800.),
    highlight:false
  };
  let pts = board.create('intersection', [Ln1, Ln2], ptA);
  return pts;
}
///////////////////////////////////////////////////////////////
export function drawSlider(board, track, positionX, positionY){
     const ptA = {
       name:'',
       size: ()=>Math.round(2*board.canvasWidth/800.),
       color:'black',
       label: {
         display:'internal'
       },
       strokeWidth:()=>Math.ceil(4*board.canvasWidth/800.),
       highlight:false};
     let ptM =board.create('glider', [positionX, positionY, track], ptA);
     return ptM;
   }
//////////////////////////////////////////////
export function drawMarker(board, track, positionX, positionY){
     const ptA = {
       name:'',
	   label: {
         display:'internal'
       },
       size: ()=>Math.round(2*board.canvasWidth/800.),
       color:'black',
       highlight:false};
     let ptM =board.create('glider', [positionX, positionY, track], ptA);
     return ptM;
   }
/////////////////////////////////////////////////
export function placeLogo(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/10;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/logo.png', [boundingBox[2]-size, boundingBox[1]-size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////
export function placePlay(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/play.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////////////////////////////
export function placePause(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/pause.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////////
export function placeShuffle(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/shuffle.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
/****************************************Hint****************************/
export function placeHint(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['/assets/Hint.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
///////////////////////////////////////////////////////////////////////////
export function placeShuffleRight(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/shuffle.svg', [0.5*(boundingBox[2]+boundingBox[0])+0.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////////////////////////////////////////////////////
export function placeTest(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['/assets/puzzle.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
////////////////////////////////////////////////////////////////////////////
export function placeRight(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/redo.svg', [0.5*(boundingBox[2]+boundingBox[0])+0.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
///////////////////////////////////////////////////////////
export function placeLeft(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/undo.svg', [0.5*(boundingBox[2]+boundingBox[0])-1.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
///////////////////////////////////////////
export function placeUndo(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/previous.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
/////////////////////////////////////////////
export function placeRedo(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/play.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////////////////////////
export function placeDynamic(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['assets/dynamic.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
///////////////////////////////////////////
export function placeStatic(board, loc)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  const x = function(){if(loc=='left')
  {return  0.5*(boundingBox[2]+boundingBox[0])-1.4*size
  }
  else{
    return 0.5*(boundingBox[2]+boundingBox[0])+0.4*size
      }
  }
  let im =board.create('image', ['/assets/static.svg', [x, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
///////////////////////////////////////////////////////////
export function placeErase(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/eraser.svg', [0.5*(boundingBox[2]+boundingBox[0])+0.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
////////////////////////////////////
export function placeEraseLeft(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/eraser.svg', [0.5*(boundingBox[2]+boundingBox[0])-1.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////
export function placeStat(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['https://s3.amazonaws.com/edliy.com/assets/statistics.svg', [0.5*(boundingBox[2]+boundingBox[0])+0.4*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
/////////////////////////////////////
export function makeResponsive(board) {
    let resizeFunction = function() {
        const bb = board.attr.boundingbox;
        board.resizeContainer(board.containerObj.clientWidth,
                              board.containerObj.clientHeight,
                              true,
                              true);
        board.setBoundingBox(bb);
        board.fullUpdate();
    };
    window.addEventListener("resize", resizeFunction);
}
////////////////////////////////////////PLACE ANGLE/////////////////////////////
export function drawAngle(board, Pt1, Pt2, Pt3){
  const lm = {
    radius:0.5,
    strokeWidth:1,
    strokeColor:'black',
    fillColor:'red',
    highlight:false,
    radiusPoint:{visible:false},
    label:{color:'black', anchorX:'middle', anchorY:'middle', fontSize:function(){return 24*board.canvasHeight/800},
    cssStyle:'fontFamily:Oswald;'}
    }
  let le=board.create('nonreflexangle', [Pt1, Pt2, Pt3], lm);
  le.setAttribute({center:{visible:true}, radiusPoint:{visible:true}, anglePoint:{visible:true}});
  return le;
}
//////////////////////////////
export function print(board, value)
{
  const boundingBox = board.attr.boundingbox;
  const size =(boundingBox[2]-boundingBox[0])/12;
  const positionX = -(boundingBox[2]-boundingBox[0])/2+0.5;
  const positionY = (boundingBox[2]-boundingBox[0])/2-0.5;
  const m ={
  anchorX: 'left',
  display: 'html',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(18*board.canvasWidth /800.)
};
printArray.push(value);
let i = printArray.length-1;
board.create('text', [positionX, positionY-i, printArray[i]], m);
}
///////////////////////////////////////////LINE////////////////////////////////
export function drawSegment(board, Pt1, Pt2){
  const lm ={
  fixed:true,
  strokeColor:'black',
  highlight:false,
  strokeWidth: () => Math.round(2*board.canvasWidth /800.)};
  let le=board.create('segment', [[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()]] , lm);
  return le;
}
/////////////////////////////////////////////////////////////////////////////
export function drawLine(board, Pt1, Pt2){
  const lm ={
  fixed:true,
  strokeColor:'black',
  highlight:false,
  strokeWidth: () => Math.round(2*board.canvasWidth /800.)};
  let le=board.create('line', [[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()]] , lm);
  return le;
}
/////////////////////////////////////////////////////////////////////
export function drawPoint(board, positionX, positionY){
     board.options.point.showInfoBox=false;
     const ptA = {
       name:'',
       label: {
         display:'internal'
       },
       size: ()=>Math.round(2*board.canvasWidth/800.),
       fillColor:'red',
       visible:true,
       strokeColor:'red',
       highlight:false};
     let ptM =board.create('point', [positionX, positionY], ptA);
     return ptM;
   }
///////////////////////////////////////////////////////
export function drawCircle(board, Pt1, Pt2){
  const lm ={
  fixed:true,
  strokeColor:'red',
  fillColor:'white',
  fillOpacity:0,
  highlight:false,
  strokeWidth: () => Math.round(4*board.canvasWidth /800.)};
  let le=board.create('circle', [[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()]], lm);
  return le;
}
////////////////////////////////
export function plotFunction(board, fx, xmin, xmax)
{
  const lm ={
  fixed:true,
  strokeColor:'red',
  highlight:false,
  strokeWidth: () => Math.round(4*board.canvasWidth /800.)};
  let pt = board.create('functiongraph',
                         [fx, xmin, xmax],lm);
  return pt;
}
//////////////////////////////////////////////////////
export function writeSmallText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(18*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
///////////////////////////////////////
export function writeMediumText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
/////////////////////////////////////////////////
export function writeLargeText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(30*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
///////////////////////////////////////////////////
export function setColor(board, obj, cols){
  obj.setAttribute({
  strokeColor:cols,
  fillColor:cols});
}
export function setWidth(board, obj, width){
  obj.setAttribute({
  strokeWidth: () => Math.round(width*board.canvasWidth /800.)});
}
export function setSize(board, obj, size){
  obj.setAttribute({
  size: () => Math.round(size*board.canvasWidth /800.)});
}
export function labelIt(board, obj, namee){
  obj.setAttribute({name:namee});
  obj.label.setAttribute({
      color:'black',
      offset:[0,20],
      cssStyle:'fontFamily:Oswald',
      fontSize:()=>Math.round(20*board.canvasWidth/800.)
      })
}
export function showInfo(board, obj){
  board.options.point.showInfoBox=true
}
export function setFix(board, obj){
  obj.setAttribute({
  fixed:true})
}
//////////////////////////////////////////////////////
export function drawDash(board, Pt1, Pt2){
  const lm ={
  fixed:true,
  dash:1,
  strokeColor:'red',
  highlight:false,
  strokeWidth: () => Math.round(2*board.canvasWidth /800.)};
  let le=board.create('segment', [[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()]] , lm);
  return le;
}
/////////////////////////// Arc /////////////////////////////
export function drawArc(board, Pt1, Pt2, Pt3){
  const lm ={
  fixed:true,
  dash:1,
  strokeColor:'red',
  fillOpacity:1,
  highlight:false,
  strokeWidth: () => Math.round(2*board.canvasWidth /800.)};
  let le=board.create('arc', [[()=>Pt1.X(), ()=>Pt1.Y()],[()=>Pt2.X(), ()=>Pt2.Y()],[()=>Pt3.X(), ()=>Pt3.Y()]], lm);
  le.setAttribute({center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
  return le;
}
////////////////////////////////Arrow//////////////////////////////
export function drawArrow(board, pt1, pt2){
     const recAttributes = {
       strokeWidth: () => Math.round(2*board.canvasWidth/800.),
       strokeColor:'red',
       highlight:false,
       fillOpacity:1.0}
     let m =board.create('arrow', [[()=>pt1.X(), ()=>pt1.Y()],
                                    [()=>pt2.X(), ()=>pt2.Y()]], recAttributes);
     return m;
   }
//////////////////////////////////////TEXT/////////////////////////////
export function placeText(board, positionX, positionY, size, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(size*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
////////////////////////////////////////////////////////
export function writeHTMLText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeMiddleText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeLeftText(board, positionX, positionY, message){
  const m ={
  anchorX: 'left',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeRightText(board, positionX, positionY, message){
  const m ={
  anchorX: 'right',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeBoldText(board, positionX, positionY, message){
  const m ={
  anchorX: 'middle',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;fontWeight:bold',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeBoldRightText(board, positionX, positionY, message){
  const m ={
  anchorX: 'right',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;fontWeight:bold',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
export function placeBoldLeftText(board, positionX, positionY, message){
  const m ={
  anchorX: 'left',
  display: 'internal',
  highlight: false,
  fixed: true,
  anchorY: 'middle',
  CssStyle: 'fontFamily:Oswald;fontWeight:bold',
  highlight:false,
  fontSize: () => Math.round(24*board.canvasWidth /800.)};
  let ele =board.create('text', [positionX, positionY, message], m)
  return ele;
}
/////////////////////////// Draggable Point/////////////////////////////////////
export function dragMe(board, positionX, positionY){
   const pointAttributes ={
    name:'Drag me',
    size:3,
    strokeColor:'black',
    fillColor:'white',
    fixed:false,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return [pt];
   }
//////////////////////////HiddenPoint////////////////////////////////////////
export function hiddenPt(board, positionX, positionY){
   const pointAttributes ={
    name:'Drag me',
    size:3,
    strokeColor:'black',
    fillColor:'white',
    fixed:false,
    visible:false,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return pt;
   }
///////////////////////////FixedPoint/////////////////////////////////////////
export function fixedPt(board, positionX, positionY, size, name, cols1, cols2){
   const pointAttributes ={
    name:name,
    size: ()=>Math.round(size*board.canvasWidth/800.),
    strokeWidth:()=>Math.ceil(2*board.canvasWidth/800.),
    fillColor:cols1,
    strokeColor:cols2,
    highlight:false,
    fixed:true,
    label:{color:'blue',
           offset:[0,20],
           cssStyle:'fontFamily:Oswald',
           fontSize:()=>Math.round(16*board.canvasWidth/800.)
          }
   };
   let pt = board.create('point',[positionX, positionY], pointAttributes);
   return pt;
   }
/////////////////////////////////////////////////////////////////////////////
//////////////////////////////Gravity ////////////////////////////////////////
export function placeGlider(board, line, positionX, positionY, size, cols1, cols2){
     const ptA = {
       name:'',
       size: ()=>Math.round(size*board.canvasWidth/800.),
       strokeWidth:()=>Math.ceil(2*board.canvasWidth/800.),
       fillColor:cols1,
       strokeColor:cols2,
       highlight:false};
     let ptM =board.create('glider', [positionX, positionY, line], ptA);
     return ptM;
   }
//////////////////////////////////////////////////////////////////////////////////
export function placeGravity(board, pt1, pt2, val)
 {
     const arrowAttributes = {
       fixed:true,
       highlight:false,
       strokeWidth: () => Math.round(3*board.canvasWidth/800.),
       strokeColor:'black',
       fillOpacity:1.0
     };
     const gAttributes={
       fixed:true,
       highlight:false,
       anchorX: 'left',
       anchorY:'middle',
       color:'black',
       CssStyle: 'font-weight:bold; fontFamily:Oswald;',
       fontSize: () => Math.round(24*board.canvasWidth/800.)
     };
     let gtext =board.create('text', [0.5*(pt1[0]+pt2[0])+Math.abs(pt1[1]-pt2[1])*0.25, 0.5*(pt1[1]+pt2[1])+Math.abs(pt1[0]-pt2[0])*0.25, val], gAttributes);
     let garrow=board.create('arrow', [
                                     [pt1[0], pt1[1]],
                                     [pt2[0], pt2[1]]
                                     ], arrowAttributes);

     return [garrow, gtext];
   }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export function placeRuler(board, pt1, pt2, val)
   {
       const arrowAttributes = {
         fixed:true,
         highlight:false,
         strokeWidth: () => Math.round(3*board.canvasWidth/800.),
         strokeColor:'black',
         fillOpacity:1.0
       };
       const gAttributes={
         fixed:true,
         highlight:false,
         anchorX: 'middle',
         anchorY:'middle',
         color:'black',
         display:'internal',
         CssStyle: 'font-weight: bold;fontFamily:Oswald;',
         fontSize: ()=>Math.round(28*board.canvasWidth/800.)
       };
       let garrow=board.create('arrow', [[pt1[0], pt1[1]],[pt2[0], pt2[1]]], arrowAttributes);
       let gtext =board.create('text',[0.5*(1.00*pt1[0]+1.00*pt2[0])+Math.abs(1.00*pt1[1]-1.00*pt2[1])*0.25, 0.5*(1.00*pt1[1]+1.00*pt2[1])+Math.abs(1.00*pt1[0]-1.00*pt2[0])*0.25, val], gAttributes);
       return [garrow, gtext];
    }
///////////////////////////////////////////////////////////////////////////////
export function placeTape(board, pt1, pt2, val)
   {
       const arrowAttributes = {
         name:val,
         fixed:true,
         point1:{face:'circle', size:2, fillColor:'red'},
         point2:{face:'circle', size:2, fillColor:'red'},
         highlight:false,
         strokeWidth: () => Math.round(3*board.canvasWidth/800.),
         strokeColor:'black',
         fillOpacity:1.0,
         label:{fixed:true,
         highlight:false,
         anchorX: 'left',
         anchorY:'middle',
         color:'black',
         display:'internal',
         CssStyle: 'font-weight:normal; fontFamily: Oswald;',
         fontSize: () => Math.round(28*board.canvasWidth /800.)}
       };
       const gAttributes={
         fixed:true,
         highlight:false,
         anchorX: 'middle',
         anchorY:'middle',
         color:'black',
         display:'internal',
         CssStyle: 'font-weight: bold; fontFamily: Oswald;',
         fontSize: () => Math.round(28*board.canvasWidth /800.)
       };
       let garrow=board.create('tapemeasure', [[pt1[0], pt1[1]],[pt2[0], pt2[1]]], arrowAttributes);
       //let gtext =board.create('text',[0.5*(1.00*pt1[0]+1.00*pt2[0])+Math.abs(1.00*pt1[1]-1.00*pt2[1])*0.25, 0.5*(1.00*pt1[1]+1.00*pt2[1])+Math.abs(1.00*pt1[0]-1.00*pt2[0])*0.25, val], gAttributes);
       return garrow;
    }
/////////////////////////////////////////////////////////////////////////////////////////////
export function placeTapeHori(board, pt1, pt2, val)
   {
       const arrowAttributes = {
         name:val,
         fixed:true,
         point1:{face:'circle', size:2, fillColor:'red'},
         point2:{face:'circle', size:2, fillColor:'red'},
         highlight:false,
         strokeWidth: () => Math.round(3*board.canvasWidth/800.),
         strokeColor:'black',
         fillOpacity:1.0,
         label:{fixed:true,
         highlight:false,
         anchorX: 'middle',
         anchorY:'middle',
         color:'black',
         display:'internal',
         CssStyle: 'font-weight:normal; fontFamily: Oswald;',
         fontSize: () => Math.round(20*board.canvasWidth /800.)}
       };
       let garrow=board.create('tapemeasure', [[pt1[0], pt1[1]],[pt2[0], pt2[1]]], arrowAttributes);
       //let gtext =board.create('text',[0.5*(1.00*pt1[0]+1.00*pt2[0])+Math.abs(1.00*pt1[1]-1.00*pt2[1])*0.25, 0.5*(1.00*pt1[1]+1.00*pt2[1])+Math.abs(1.00*pt1[0]-1.00*pt2[0])*0.25, val], gAttributes);
       return garrow;
    }
/////////////////////////////////////////////////////////
export function placeTapeVert(board, pt1, pt2, val)
   {
       const arrowAttributes = {
         name:val,
         fixed:true,
         point1:{face:'circle', size:2, fillColor:'red'},
         point2:{face:'circle', size:2, fillColor:'red'},
         highlight:false,
         strokeWidth: () => Math.round(3*board.canvasWidth/800.),
         strokeColor:'black',
         fillOpacity:1.0,
         label:{fixed:true,
         highlight:false,
         anchorX: 'middle',
         anchorY:'middle',
         color:'black',
         display:'internal',
         CssStyle: 'font-weight:normal; fontFamily: Oswald;',
         fontSize: () => Math.round(20*board.canvasWidth /800.)}
       };
       let garrow=board.create('tapemeasure', [[pt1[0], pt1[1]],[pt2[0], pt2[1]]], arrowAttributes);
       //let gtext =board.create('text',[0.5*(1.00*pt1[0]+1.00*pt2[0])+Math.abs(1.00*pt1[1]-1.00*pt2[1])*0.25, 0.5*(1.00*pt1[1]+1.00*pt2[1])+Math.abs(1.00*pt1[0]-1.00*pt2[0])*0.25, val], gAttributes);
       return garrow;
    }
///////////////////////////////////////Polygon////////////////////////////////////////
export function placeRec(board, pt1, pt2, pt3, pt4, cols){
  const recAttributes = {
    vertices:{visible:false, fixed:true},
    borders:{visible:false},
    highlight:false,
    fillColor:cols,
    fillOpacity:1.0}
  let m =board.create('polygon', [[pt1[0], pt1[1]],
                                 [pt2[0], pt2[1]],
                                 [pt3[0], pt3[1]],
                                 [pt4[0], pt4[1]]], recAttributes);
  return m;
}
/////////////////////////////////////////////////////////////////
export function placeSq(board, pt1, pt2, cols){
  const recAttributes = {
    vertices:{visible:false, fixed:true},
    borders:{visible:false},
    highlight:false,
    fillColor:cols,
    fillOpacity:1.0}
  let m =board.create('polygon', [[pt1[0], pt1[1]],
                                 [pt2[0], pt1[1]],
                                 [pt2[0], pt2[1]],
                                 [pt1[0], pt2[1]]], recAttributes);
  return m;
}
/////////////////////////////////////////////////////////////////
export function drawTriangle(board, pt1, pt2, pt3){
  const recAttributes = {
    vertices:{visible:false, fixed:true},
    borders:{visible:false},
    highlight:false,
    fillColor:'blue',
    fillOpacity:0.5}
  let m =board.create('polygon', [[()=>pt1.X(), ()=>pt1.Y()], [()=>pt2.X(), ()=>pt2.Y()],
  [()=>pt3.X(), ()=>pt3.Y()]], recAttributes);
  return m;
}
//////////////////////////////////////////////Circle/////////////////////////////////////
export function placeSlider(board, positionX, positionY, limit1, start, limit2, length, name)
{
  const sliderAttributes={
    baseline:{strokeWidth:7*board.canvasHeight/800, strokeColor:'grey', highlight:false},
    highline:{strokeWidth:7*board.canvasHeight/800, strokeColor:'black', highlight:false},
    name:name,
    highlight:false,
    size:()=>8*board.canvasHeight/800,
    face:'square',
    fillColor:'grey',
    strokeColor:'black',
    withTicks:false,
    precision:2,
    label:{fontSize:()=> Math.round(18*board.canvasWidth/800.), offset:[15,0],
    cssStyle:'fontFamily:Oswald;', highlight:false}
  }
   let m = board.create('slider',[[positionX, positionY],[positionX+length, positionY],[limit1, start, limit2]], sliderAttributes);    //
   return m;
}
//////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////
export function placeStartOver(board)
{
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0,
  };
  const boundingBox = board.attr.boundingbox;
  const positionX = (boundingBox[2]+boundingBox[0])/2;
  const positionY = (boundingBox[1]+boundingBox[3])/2;
  const size =(boundingBox[2]-boundingBox[0])/13;
  let im =board.create('image', ['/assets/replay.svg', [positionX+2.2*size, boundingBox[3]+0.50*size],[size,size]], imageAttributes);
  return im;
}
//////////////////////////////////////////////////////////////////////////////////
export function placeSliderSwitch(board, positionX, positionY, limit1, limit2, length, name)
{
  const sliderAttributes={
    baseline:{strokeWidth:7*board.canvasHeight/800,strokeColor:'grey'},
    highline:{strokeWidth:7*board.canvasHeight/800,strokeColor:'black'},
    name:name,
    size:()=>8*board.canvasHeight/800,
    highlight:false,
    face:'square',
    fillColor:'#FF0000',
    strokeColor:'black',
    snapWidth:1,
    withTicks:false,
    label:{anchorX:'left',fontSize:()=> Math.round(24*board.canvasWidth/800.),
    cssStyle:'fontFamily:Oswald;'}
  }
   let m = board.create('slider',[[positionX, positionY],[positionX+length, positionY],[limit1, 0.5*(limit1+limit2), limit2]], sliderAttributes);    //
   return m;
}
/////////////////////////////////////////////////////////////////
export function placeImage(board, imValue, positionX, positionY){
  const imageAttributes ={
   opacity:1,
   fixed:true,
   rotate:0
  };
   let im =board.create('image', [imValue, [positionX, positionY],[1,1]], imageAttributes);
   return im;
}
//////////////////////////////////////////////////////////
export function placeInput(board, positionX, positionY, val){
  const inputAttributes ={
    fixed: true,
    cssStyle: 'fontFamily:Oswald; width:10%; background-color:#008CBA;border:1px solid black;border-radius:3.5px;',
    fontSize:function(){return Math.round(24*board.canvasWidth/800.)},
    fixed:true,
    alignY:'bottom',
    parse:true
  }
   let inp =board.create('input', [positionX, positionY, val, ''], inputAttributes);
   return inp;
}
///////////////////////////////Shuffle Function ////////////////////////////
export function shuffle(listName, varName){
  if(varName<listName.length-1)
  {
    return varName +=1;
  }
  else{
    return varName=0;
  }
}
///////////////////////////////////////////////////////////////////////////////
export function toggle(i){
  if(i==0)
  {
    return 1;
  }
  else{
    return 0;
  }
}
/////////////////////////////////////////////////////////////////////////
export function toggleTF(i){
  if(i==true)
  {
    return false;
  }
  else{
    return true;
  }
}
/////////////////////////////////////////Hover Over //////////////////////////////
export function hoverMe(board, element, message, Xoffset, Yoffset)
{
  element.setLabel(message);
  element.label.setAttribute({visible:false, anchorX:'left', anchorY:'top',
                              offset:[Xoffset, Yoffset],
                              CssStyle:'fontFamily:Oswald;color:black',
                              fontSize:function(){return Math.round(18*board.canvasWidth/800.)}});
  element.on('over', function () {this.label.setAttribute({visible:true});});
  element.on('out', function () {this.label.setAttribute({visible:false});});
}
//////////////////////////////////////////////////////////////////////////////////////////
export function placeLabel(board, element, message, Xoffset, Yoffset)
{
  element.setLabel(message);
  element.label.setAttribute({
                              offset:[Xoffset, Yoffset],
                              anchorX:'left',
                              anchorY:'middle',
                              CssStyle:'fontFamily:Oswald;',
                              fontSize:function(){return Math.round(18*board.canvasWidth/800.)}});
}
/////////////////////////////////////////////////////////////////////////
export function placeScore(board, titleText)
{
  const commonTextAttributes = {
      anchorX: 'middle',
      display: 'html',
      highlight: false,
      fixed: true,
	  visible:true
  };
  const titleTextAttributes = {
      ...commonTextAttributes,
      anchorY: 'middle',
      CssStyle: 'font-weight: bold; fontFamily: Oswald;',
      highlight:false,
      fontSize: () => Math.round(32 * board.canvasWidth /800.)
  };

  const boundingBox = board.attr.boundingbox;

  let title = board.create(
      'text',
      [(boundingBox[0] + boundingBox[2]) / 2.0,
       (boundingBox[1] + boundingBox[3]) / 2.0,
       function(){return titleText+'/5'}], titleTextAttributes);

  return title;
}
///////////////////////////////////////////////////////////////////////
export function placeWhite(board){
  const imageAttributes ={
   opacity:0,
   fixed:true,
   visible:true
  };
    const boundingBox = board.attr.boundingbox;
    const size = 20;
    let im =board.create('image', ['/assets/square.svg', [(boundingBox[0] + boundingBox[2]-size) / 2.0,
    (boundingBox[1] + boundingBox[3]-size) / 2.0,],[size, size]], imageAttributes);
   return im;
}
//////////////////////////////////////////////////////////////////////////////
export function placeTitle(board, titleText, subTitleText){
    const commonTextAttributes = {
        anchorX: 'middle',
        display: 'internal',
        highlight: false,
        fixed: true
    };
    const titleTextAttributes = {
        ...commonTextAttributes,
        anchorY: 'middle',
        CssStyle: 'font-weight: bold; fontFamily: Oswald;',
        highlight:false,
        fontSize: () => Math.round(32 * board.canvasWidth /800.)
    };
    const subTitleTextAttributes = {
        ...commonTextAttributes,
        anchorY: 'middle',
        CssStyle: 'fontFamily: Oswald;',
        highlight:false,
        fontSize: () => Math.round(24 * board.canvasWidth /800.)
    };
    const boundingBox = board.attr.boundingbox;
    const offset = [0, -(boundingBox[2] - boundingBox[0])/20.0];
    let title = board.create(
        'text',
        [(boundingBox[0] + boundingBox[2]) / 2.0 + offset[0],
         boundingBox[1] + offset[1],
         titleText],
        titleTextAttributes
    );
    let subtitle = board.create(
        'text',
        [(boundingBox[0] + boundingBox[2]) / 2.0 + offset[0],
         boundingBox[1] + 2*offset[1],
         subTitleText],
        subTitleTextAttributes
    );
    return [title, subtitle];
}
/////////////////////////////////////////////////////////////////////////////
export function clearInputFields(board) {
    let magicGraph = board.containerObj;
    let inputFields = magicGraph.querySelectorAll("input");
    for (let inputField of inputFields) {
        inputField.value = ""
    }
}
/////////////////////////////////////////////////////////////////////////////
export function setInputFieldAnswerColor(inputObject, isAnswerCorrect) {
  // TODO: Tableau colors don't look great with blue background, find a
  // different color scheme that looks better.
  let colorizeElement = function (element, condition) {
    if (element) {
      if (condition) {
        // element.style.borderColor = '#59A14F';
        element.style.backgroundColor = 'green';
      } else {
        // element.style.borderColor = '#E15759';
        element.style.backgroundColor = 'red';
      }
    }
  }

  colorizeElement(inputObject.rendNodeInput, isAnswerCorrect);
  colorizeElement(inputObject.rendNode, isAnswerCorrect);
}
/////////////////////////////////////////////////////////////
export function markInteractive(inputObject) {
  let board = inputObject.board;
  if (!board._xedliy) {
    board._xedliy = {};
  }

  if (!board._xedliy.interactive) {
    let magicGraph = board.containerObj;
    let svgs = magicGraph.querySelectorAll("svg");
    for (let svg of svgs) {
      svg.style.pointerEvents = "none";
    }
    board._xedliy.interactive = true;
  }

  if (inputObject) {
    // console.log(inputObject);
    inputObject.rendNode.style.cursor = "pointer";
    inputObject.rendNode.style.pointerEvents = "all";
  }
}
///////////////////////////////////////////////////////////////
